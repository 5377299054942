require('./bootstrap');
$('#contact-form').submit(function(e){
    e.preventDefault();
    $.ajax({
        type:'POST',
        url:$(this).attr('action'),
        dataType: 'json',
        data:$(this).serialize(),
        success:function(data){
            alert(data.success);
        },
        error:function () {
            alert('error al enviar información');
        }
    });
});
$(".play").click(function() {
    var elemento = $(this).attr("data");
    var idioma = document.documentElement.lang;
    var audio = document.getElementById("audio");
    $('#audio').attr("src","Audio/"+elemento+"-"+idioma+".mp3");
    audio.play();
});

$(".stop").click(function() {
    var audio =document.getElementById("audio");
    audio.pause();
});

